<template>
  <v-container fluid grid-list-lg>
    <graftworx-header>
      <router-link :to="{ name: 'Index' }">Home</router-link> / Patients
    </graftworx-header>

    <div>
      <div class="patient-list-header">
        <v-btn @click="newPatientDialog = true"> New Patient </v-btn>
        <v-text-field
          v-model="patientSearchField"
          placeholder="Search by patient id"
          @keydown.enter="getDataFromApi"
        />
        <v-btn @click="getDataFromApi">Search</v-btn>
      </div>

      <v-dialog
        v-model="newPatientDialog"
        :disabled="loading"
        persistent
        no-click-animation
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            New Patient
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit="createPatient"
            >
              <v-text-field
                v-model="subjectID"
                :rules="subjectIDRules"
                :counter="30"
                :error-messages="errors"
                label="Subject ID"
                required
              >
              </v-text-field>

              <v-checkbox
                v-model="mockErrors"
                label="Generate mock alerts?"
                required
              >
              </v-checkbox>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="clear"> Cancel </v-btn>
            <v-btn color="primary" :disabled="!valid" @click="createPatient">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="assignPatchDialog" persistent no-click-animation>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Assign Patch to Patient {{ selectedPatientID }}
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <patch-table
              v-if="assignPatchDialog"
              :link="false"
              :selectable="true"
              :selected-patient-i-d="selectedPatientID"
              @assignPatches="assignPatches = $event"
              @unassignPatches="unassignPatches = $event"
            >
            </patch-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="assignPatchDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="savePatchAssignment"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="assignHubDialog" persistent no-click-animation>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Patient {{ selectedPatientID }} Hub Assignment
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <hub-table
              v-if="assignHubDialog"
              :link="false"
              :selectable="true"
              :selected-patient-i-d="selectedPatientID"
              :omit-headers="omitHubHeaders"
              @assignPatches="assignHubs = $event"
              @unassignPatches="unassignHubs = $event"
            >
            </hub-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="assignHubDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="saveHubAssignment"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="assignKitDialog" persistent no-click-animation>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Patient {{ selectedPatientID }} Kit Assignment
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <kit-search
              v-if="assignKitDialog"
              @query="assignKit = $event"
              @errors="errors = $event"
            >
            </kit-search>
          </v-card-text>

          <v-divider></v-divider>
          <div>
            <v-alert :value="kitError" dismissible text type="error">
              Kit does not exist
            </v-alert>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="clearKit"> Cancel </v-btn>
            <v-btn
              color="primary"
              :disabled="kitAssigned"
              @click="saveKitAssignment"
            >
              Save
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!kitAssigned"
              @click="saveKitAssignment"
            >
              Reassign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="assignDevicesDialog" persistent no-click-animation>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Patient {{ selectedPatientID }} Device Assignment
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <!--          <hub-table-->
            <!--            v-if="assignHubDialog"-->
            <!--            :link="false"-->
            <!--            :selectable="true"-->
            <!--            :selectedPatientID="selectedPatientID"-->
            <!--            :omitHeaders="omitHubHeaders"-->
            <!--            @assignPatches="assignHubs = $event"-->
            <!--            @unassignPatches="unassignHubs = $event">-->
            <!--          </hub-table>-->
            things happen here

            <hub-search @query="host = $event"> </hub-search>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="assignDevicesDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="saveDeviceAssignment"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="sendNotificationDialog" persistent no-click-animation>
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Send Notification to {{ selectedPatientID }}
          </v-card-title>

          <v-card-text v-if="saving">
            <v-layout row wrap align-center>
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="15"
                    color="teal"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <div>
              <v-select
                v-model="chosenMetric"
                :items="notificationMetrics"
                label="Metric"
              />
              <v-select
                v-model="chosenMetricValue"
                :items="notificationValues"
                label="Values"
              />
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="sendNotificationDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="sendNotification"> Send </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        must-sort
        class="elevation-1"
        :rows-per-page-items="rowsPerPageOptions"
        :headers="headers"
        :items="patients"
        :loading="loading"
        :pagination.sync="pagination"
        :total-items="total"
      >
        <template slot="no-data">
          <div align="center">
            <span v-if="loading">Loading</span>
            <span v-else-if="error">
              Something has gone wrong.
              <v-btn @click="getDataFromApi">Reload</v-btn>
            </span>
            <span v-else>No data</span>
          </div>
        </template>

        <template slot="items" slot-scope="props">
          <td>{{ props.item.subject_id }}</td>
          <td>
            <v-btn
              v-if="allowAssignDevices"
              @click="openDeviceDialog(props.item.subject_id)"
            >
              Assign devices
            </v-btn>
            <v-btn @click="openHubDialog(props.item.subject_id)">
              Hub Assignment
            </v-btn>
            <v-btn @click="openPatchDialog(props.item.subject_id)">
              Manage patches assigned to patient {{ props.item.subject_id }}
            </v-btn>
            <v-btn @click="openKitDialog(props.item.subject_id)">
              Issue kit
            </v-btn>
            <v-btn @click="openKitDialog(props.item.subject_id)">
              Issue kit
            </v-btn>
            <v-btn
              @click="
                openNotificationDialog(
                  props.item.subject_id,
                  props.item.active_patch_mac
                )
              "
            >
              Send notification
            </v-btn>
          </td>
        </template>

        <template slot="footer">
          <td colspan="100%">
            <div class="text-xs-center pt-2">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
              ></v-pagination>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Patients",
  data() {
    return {
      loading: true,
      error: false,
      newPatientDialog: false,
      valid: false,
      subjectID: "",
      mockErrors: false,
      total: 0,
      pages: 0,
      pagination: {
        rowsPerPage: 20,
      },
      saving: false,
      errors: [],
      patients: [],
      rowsPerPageOptions: [10, 20, 50, 100, 200],
      headers: [
        { text: "Patient ID", value: "subject_id" },
        { text: "", value: "" },
      ],
      subjectIDRules: [
        (v) => !!v || "Subject ID is required",
        (v) =>
          (v && v.length <= 30) || "Subject ID must be 30 characters or fewer",
      ],

      // Device assignment
      // TODO: The assign devices functionality has been altered to only assigning kits for the time being
      allowAssignDevices: false,
      selectedPatientID: "",
      assignKitDialog: false,
      assignDevicesDialog: false,
      kitAssigned: false,
      kitError: false,

      // Patches
      assignPatchDialog: false,
      assignPatches: [],
      unassignPatches: [],

      // Hubs
      assignHubDialog: false,
      omitHubHeaders: [
        "vpn_ip",
        "modem_type",
        "data_plan",
        "sim_card_number",
        "imei",
      ],
      assignHubs: [],
      unassignHubs: [],

      // Kits
      assignKit: null,

      // notifications
      sendNotificationDialog: false,
      notificationsTypes: [],
      chosenMetric: null,
      chosenMetricValue: null,

      //search
      patientSearchField: null,

      // selected mac
      selectedMac: null,
    };
  },

  computed: {
    notificationMetrics() {
      return this.notificationsTypes.map((type) => type.metric);
    },
    notificationValues() {
      if (this.chosenMetric) {
        const type = this.notificationsTypes.find(
          (type) => type.metric === this.chosenMetric
        );
        return type.values;
      }
      return [];
    },
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit("changeTitle", "Alio Patients");
  },

  mounted() {
    this.getNotificationsTypes();
  },

  methods: {
    sendNotification() {
      this.loading = true;

      this.$store
        .dispatch("sendNotification", {
          metric: this.chosenMetric,
          value: this.chosenMetricValue,
          patient_id: this.selectedPatientID,
          patch_mac: this.selectedMac,
        })
        .then((data) => {})
        .catch((err) => {
          this.error = true;
        })
        .finally(() => {
          this.sendNotificationDialog = false;
          this.loading = false;
        });
    },
    getNotificationsTypes() {
      this.$store
        .dispatch("getNotificationsTypes")
        .then((data) => {
          this.notificationsTypes = data;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },
    openNotificationDialog(subjectID, patchMac) {
      this.chosenMetric = null;
      this.chosenMetricValue = null;
      this.selectedPatientID = subjectID;
      this.selectedMac = patchMac;
      this.sendNotificationDialog = true;
    },
    getDataFromApi() {
      this.loading = true;

      const payload = {
        page: this.pagination.page,
        pageSize: this.pagination.rowsPerPage,
        sort: this.pagination.sortBy,
        descending: this.pagination.descending,
      };

      if (this.patientSearchField) {
        payload.subject_id = this.patientSearchField;
      }

      this.$store
        .dispatch("fetchPatients", payload)
        .then((data) => {
          this.patients = data.patients;
          this.total = data.total;
          this.pages = data.pages;

          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
        });
    },

    createPatient() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$store
          .dispatch("createPatient", {
            subject_id: this.subjectID,
            mock_errors: this.mockErrors,
          })
          .then((newPatient) => {
            this.patients.push(newPatient);
            this.clear();
          })
          .catch((err) => {
            if (err.response.data.message === "duplicate key") {
              this.errors.push("subject_id must be unique");
            }

            this.saving = false;
          });
      }
    },

    clear() {
      this.newPatientDialog = false;
      this.saving = false;
      this.errors = [];
      this.$refs.form.reset();
    },

    clearKit() {
      this.assignKitDialog = false;
      this.saving = false;
      this.kitError = false;
      this.assignKit = null;
    },

    openPatchDialog(subjectID) {
      this.selectedPatientID = subjectID;
      this.assignPatchDialog = true;
    },

    savePatchAssignment() {
      if (!this.assignPatches.length && !this.unassignPatches.length) {
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("assignPatches", {
          patient_id: this.selectedPatientID,
          assign: this.assignPatches,
          unassign: this.unassignPatches,
        })
        .then((data) => {
          console.log("data:", data);
          this.assignPatchDialog = false;
          this.saving = false;
        });
    },

    openHubDialog(subjectID) {
      this.selectedPatientID = subjectID;
      this.assignHubDialog = true;
    },

    saveHubAssignment() {
      if (!this.assignHubs.length && !this.unassignHubs.length) {
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("assignHubs", {
          patient_id: this.selectedPatientID,
          assign: this.assignHubs,
          unassign: this.unassignHubs,
        })
        .then((data) => {
          console.log("data:", data);
          this.assignHubDialog = false;
          this.saving = false;
        });
    },

    openKitDialog(subjectID) {
      this.selectedPatientID = subjectID;
      this.assignKitDialog = true;
      this.kitAssigned = false;
    },

    saveKitAssignment() {
      console.log(this.kitError);
      if (!this.assignKit.length) {
        return;
      }

      if (typeof this.assignKit != "object") {
        console.log("Kit does not exist");
        this.errors.push("Kit does not exist");
        this.kitError = true;
        return;
      }

      this.kitError = false;

      if (this.kitAssigned === false && this.assignKit[1] !== null) {
        this.kitAssigned = true;
        return;
      }

      this.saving = true;
      this.$store
        .dispatch("assignKits", {
          patient_id: this.selectedPatientID,
          assign: [this.assignKit[0]],
          // unassign: this.unassignKits
        })
        .then((data) => {
          console.log("data:", data);
          this.assignKitDialog = false;
          this.saving = false;
        })
        .catch((err) => {
          if (err.response.data.message === "Kits not found") {
            this.errors.push("Kit does not exist");
          }

          this.saving = false;
        });
    },

    openDeviceDialog(subjectID) {
      this.selectedPatientID = subjectID;
      this.assignDevicesDialog = true;
    },

    saveDeviceAssignment() {
      this.assignDevicesDialog = false;
      this.saving = false;
      // if (!this.assignHubs.length && !this.unassignHubs.length) {
      //   return;
      // }
      //
      // this.saving = true;
      // this.$store.dispatch('assignHubs', {
      //   patient_id: this.selectedPatientID,
      //   assign: this.assignHubs,
      //   unassign: this.unassignHubs
      // })
      // .then(data => {
      //   console.log('data:', data);
      //   this.assignDevicesDialog = false;
      //   this.saving = false;
      // });
    },
  },
};
</script>

<style lang="css" scoped>
.patient-list-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;
}
</style>
